import firebase from 'firebase';


export default async ()=> {
  const creds = [];
  const allCreds = await firebase.firestore().collection('credentials').where("active", "==", true).get();

  allCreds.forEach(async (doc)=>{
    const credData = doc.data();

    creds.push({
      displayName: credData.displayName,
      value: credData.value,
    });
  });


  creds.sort((a, b)=>{
    const textA = a.displayName.toUpperCase();
    const textB = b.displayName.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });

  return creds;
};
